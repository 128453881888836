.App {
  text-align: center;
}

body{
  background-color: rgb(25, 32, 34);
}
td, #tournament, #form{
  text-align: center;
}
.container{
  background: #444;
  width: 100%;
  border-radius: 5px;
}
#team_host, #team_guest{
  width: 40%;
  font-size: 70px;
  color: white;
}
#score_host, #score_guest{
  font-size: 80px;
}
.timer{
  font-size: 50px;
}
#score_host, #score_guest, .timer{
  cursor: pointer;
  color: #B5A825;
}
.show-counter{
  color: white;
}
#tablo{
  margin-top: 100px;
  border-radius: 10px;
}
#form{
  margin-top: 10px;
  border-radius: 10px;
}


@media (max-width: 992px){
  #team_host, #team_guest {
    font-size: 30px;
  }
}

@media (max-width: 768px) {

  #team_host, #team_guest {
    font-size: 20px;
  }
}

@media (max-width: 400px) {

  #team_host, #team_guest {
    font-size: 10px;
  }
}

.App-header {
  background-color: rgb(56, 59, 57);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(5px + 2vmin);
  color: #B5A825;
}

.matches{
  color: #B5A825;;
}

.t_head > p {
  font-size: 50px;
  font-stretch: ultra-condensed;
  padding-bottom: 0;
  padding-top: 20px;
}

.matches > p {
  margin: 0;
}

.d-grid {
  display: grid!important;
  align-content: flex-start;
}

.App-link {
  color: #B5A825;;
}

.Word {
  width: 100%;
  text-align: center;
  border: 5px solid #ffe500;
  border-radius: 20px;
  margin-bottom: 2px;
  margin-top: 2px;
}

input {
  color: antiquewhite;
  border: 5px solid #8e9698;
  padding: 0.5rem;
  background-color: #363535;
}

.definition{
  color: #00ffe4;
  font-size: 25px;
  font-family: serif;
  margin-top: 0;
  margin-bottom: 0;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  /*max-height: 143px;*/
  overflow-y: auto;
  padding-left: 0;
  /*width: calc(300px + 1rem);*/
}

.suggestions li {
  /*padding: 0.5rem;*/
}

.suggestion-active,
.suggestions li:hover {
  background-color: #737973;
  color: #8e9698;
  cursor: pointer;
  font-weight: 500;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.hidden { display:none; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
